@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "F37 Zagma";
  src: url("../assets/fonts/F37Zagma-Bold.otf") format("opentype"),
    url("../assets/fonts/F37Zagma-Bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "F37 Zagma";
  src: url("../assets/fonts/F37Zagma-Regular.otf") format("opentype"),
    url("../assets/fonts/F37Zagma-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "F37 Zagma";
  src: url("../assets/fonts/F37Zagma-RegularItalic.otf") format("opentype"),
    url("../assets/fonts/F37Zagma-RegularItalic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "F37 Zagma";
  src: url("../assets/fonts/F37Zagma-Light.otf") format("opentype"),
    url("../assets/fonts/F37Zagma-Light.ttf") format("truetype");
  font-weight: 300;
}

:root {
  /* Colors */
  --wagelink-blue: 8 104 240;
  --wagelink-light-blue: 159 200 254;
  --wagelink-light-grey: 224 225 221;
  --wagelink-orange: 225 113 63;
  --wagelink-white: 250 250 250;
  --wagelink-purple: 111 56 220;
  --wagelink-dark: 17 26 57;

  /* Fonts */
  --wagelink-fs-headline-2: 60px;
  --wagelink-fs-sub-head-caps: 30px;
  --wagelink-fs-sub-head: 33px;
  --wagelink-fs-body-1B: 33px;
  --wagelink-fs-body-2: 22px;
  --wagelink-fs-body: 20px;

  --wagelink-lh-headline-2: 62px;
  --wagelink-lh-sub-head-caps: 45px;
  --wagelink-lh-sub-head: 42px;
  --wagelink-lh-body-1B: 38px;
  --wagelink-lh-body-2: 30px;
  --wagelink-lh-body: 30px;

  --wagelink-ls-headline-2: -0.6px;
  --wagelink-ls-sub-head-caps: 1.5px;
  --wagelink-ls-sub-head: -0.33px;
  --wagelink-ls-body-1B: -0.33px;
  --wagelink-ls-body-2: -0.22px;
  --wagelink-ls-body: -0.2px;
}

@media screen and (max-width: 1535px) {
  :root {
    --wagelink-fs-headline-2: 45px;
    --wagelink-fs-sub-head-caps: 24px;
    --wagelink-fs-sub-head: 26px;
    --wagelink-fs-body-1B: 26px;
    --wagelink-fs-body-2: 18px;
    --wagelink-fs-body: 16px;

    --wagelink-lh-headline-2: 47px;
    --wagelink-lh-sub-head-caps: 34px;
    --wagelink-lh-sub-head: 30px;
    --wagelink-lh-body-1B: 30px;
    --wagelink-lh-body-2: 24px;
    --wagelink-lh-body: 24px;

    --wagelink-ls-headline-2: -0.6px;
    --wagelink-ls-sub-head-caps: 1.5px;
    --wagelink-ls-sub-head: -0.33px;
    --wagelink-ls-body-1B: -0.33px;
    --wagelink-ls-body-2: -0.22px;
    --wagelink-ls-body: -0.2px;
  }
}

@media screen and (max-width: 1023px) {
  :root {
    --wagelink-fs-headline-2: 30px;
    --wagelink-fs-sub-head-caps: 20px;
    --wagelink-fs-sub-head: 22px;
    --wagelink-fs-body-1B: 22px;
    --wagelink-fs-body-2: 16px;
    --wagelink-fs-body: 14px;

    --wagelink-lh-headline-2: 32px;
    --wagelink-lh-sub-head-caps: 28px;
    --wagelink-lh-sub-head: 26px;
    --wagelink-lh-body-1B: 26px;
    --wagelink-lh-body-2: 20px;
    --wagelink-lh-body: 20px;

    --wagelink-ls-headline-2: -0.6px;
    --wagelink-ls-sub-head-caps: 1.5px;
    --wagelink-ls-sub-head: -0.33px;
    --wagelink-ls-body-1B: -0.33px;
    --wagelink-ls-body-2: -0.22px;
    --wagelink-ls-body: -0.2px;
  }
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  @apply font-zagma;
}

.heading {
  background-image: url("../assets/images/subtract_background_img.png");
  background-repeat: no-repeat;
  background-position: bottom 95px right 0px;
  background-size: cover;
}

@media screen and (min-width: 1280px) {
  .heading-banner {
    height: calc(100vh - 120px);
    min-height: 600px;
    max-height: 780px;
  }
}

@media screen and (min-width: 1536px) {
  .heading-banner {
    height: calc(100vh - 126px);
    min-height: 700px;
    max-height: 874px;
  }
}
.footer {
  background-image: url("../assets/images/subtract_footer_background_img.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.privacy-policy-background-banner,
.terms-and-conditions-background-banner {
  position: absolute;
  width: 100%;
  height: 620px;
  background-image: url("../assets/images/subtract_privacy_policy.png");
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}
